import { ApiAction } from '../api-actions';
import { ApiResourceModule } from '../api-modules/api-resource-modules';
import { linkModuleAndAction } from '../link-module-and-action';

export const UsersPermissions = {
	ACTIVATE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.ACTIVATE),
	CREATE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.CREATE),
	DELETE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.DELETE),
	EXPORT_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.EXPORT),
	EXTEND_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.EXTEND),
	INVITE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.INVITE),
	READ_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.READ),
	RESTORE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.RESTORE),
	UPDATE_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.UPDATE),
	VERIFY_USERS_PERMISSION: linkModuleAndAction(ApiResourceModule.USERS, ApiAction.VERIFY),
};
